<template>
  <div class="wrap">
    <!-- 인트로 -->
    <div class="onepage intro layout_bottom">
      <!-- 인트로 로고 -->
      <div class="onepage_flex flex flex_item_center">
        <div class="logo">
          <img src="/assets/image/intro.png" alt="메트라락업">
        </div>
      </div>
      <!-- 인트로 로고 -->
      <!-- 인트로 입력폼 -->
      <div class="onepage_b_area flex_end">
        <div class="container">
          <div class="row_25">
            <div class="text_field">
              <div class="basic_input">
                <input type="email" v-model="email" placeholder="이메일 주소를 입력해 주세요."/>
              </div>
              <div class="basic_input">
                <input type="password" v-model="password" placeholder="비밀번호를 입력해 주세요." @keydown.enter="login">
              </div>
            </div>
            <div class="base_area" @click="login">
              <button class="one_btn l_btn fill_btn_bk radius_l">로그인</button>
            </div>
            <div class="base_area">
              <div class="find_wrap">
                <router-link :to="{ name: 'PasswordFind' }">비밀번호 찾기</router-link>
                <router-link :to="{ name: 'Signup' }">회원가입</router-link>
              </div>
            </div>
            <div class="base_area">
              <p class="copyright">Copyright © 2022 MetaMAFI Ltd. </p>
            </div>
          </div>
        </div>
      </div>
      <!-- //인트로 입력폼 -->
    </div>
    <!-- //인트로 -->
  </div>
</template>

<script>
export default {
  name: "VueLogin",

  data() {
    return {
      email: '',
      password: '',
    }
  },

  props: ['showHeader'],

  created() {
    sessionStorage.clear()
  },

  mounted() {
    this.showHeader(false)
  },

  beforeUnmount() {
    this.showHeader(true)
  },

  methods: {
    login() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.password)

      this.$post(this.$USER_LOGIN, 'postLogin', formData, false, (result) => {
        let data = result.data[0]

        this.$router.push({ name: 'Pin', params: { isPinSet: data.isPinSet ? 2 : 0, authKey: data.authKey } })
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>