<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="layout_popup">
        <div class="layout_container">
          <!-- 팝업 -->
          <div class="modal_wrap">
            <div class="modal_radius">
              <!-- 내용영역 -->
              <div class="modal_content bg_wh">
                <div class="madal_row">
                  <span class="logo_area">
                    <img src="/assets/image/logo_col.png" alt="락업로고">
                  </span>
                  <div v-show="qrScanner" class="content_area">
                    <div class="group">
                      <div v-show="qrScanner" id="webQR" class="qr-style"></div>
                    </div>
                    <button class="btn_qrcode" @click="closeQR()">닫기</button>
                  </div>
                  <div v-show="!qrScanner" class="content_area">
                    <!-- 받는주소 -->
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>받는주소</h4>
                      </div>
                      <div class="basic_input unit_input">
                        <input type="text" placeholder="주소 입력" v-model="withdrawInfo.addressTo"/>
                        <span class="unit" @click="openQR"><i class="icon-qr"></i></span>
                      </div>
                    </div>
                    <!-- 출금수량 -->
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>출금수량</h4>
                        <p>
                          <span class="info_desc_1 info">
                            내부출금 수수료
                            <em class="fee">{{ fee.internalFee }} <i class="unit">{{ fee.internalFeeType ? '%' : getCoinLabel() }}</i></em>
                          </span><br/>
                          <span class="info_desc_1 info">
                            외부출금 수수료
                            <em class="fee">{{ fee.externalFee }} <i class="unit">{{ fee.externalFeeType ? '%' : getCoinLabel() }}</i></em>
                          </span>
                        </p>
                      </div>
                      <div class="basic_input unit_input">
                        <input type="number" placeholder="수량 입력" v-model="withdrawInfo.amount" />
                        <span class="unit" @click="withdrawInfo.amount = availableBalance">MAX</span>
                      </div>
                      <p class="info_desc_1 mw_info clear">
                        <span class="left">출금가능수량</span>
                        <span class="right t_bold">{{ availableBalance }} <i class="unit t_bold">{{ getCoinLabel() }}</i></span>
                      </p>
                    </div>
                    <!-- 휴대폰인증 -->
                    <div class="group" v-show="!isOTPAuth">
                      <div class="m_input_tit">
                        <h4>휴대폰 인증</h4>
                      </div>
                      <div class="basic_input btn_input_1">
                        <input type="text" placeholder="인증 코드" v-model="withdrawInfo.phoneCode" />
                        <button class="btn_send" @click="verifyPhone">인증요청</button>
                      </div>
                    </div>
                    <div class="group" v-show="isOTPAuth">
                      <div class="m_input_tit">
                        <h4>OTP 인증</h4>
                      </div>
                      <div class="basic_input btn_input_1">
                        <input type="number" placeholder="인증 코드" v-model="withdrawInfo.otpCode" />
                      </div>
                    </div>
                    <!-- 안내정보 -->
                    <div class="group">
                      <p class="info_desc_2">
                        수량, 주소가 정확한지 확인하세요.
                        블록체인 특성상 잘못된 주소로 출금을 할 경우
                        회수가 어렵습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //내용영역 -->
              <!-- 확인버튼 -->
              <div class="modal_btn bg_bk" @click="goCheckPin">
                <div class="btn_table">
                  <div class="btn_col">
                    <button>출금하기</button>
                  </div>
                </div>
              </div>
              <!-- //확인버튼 -->
              <!-- 닫기 -->
              <div class="modal_close" @click="closePopup">
                <button><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
          <!-- //팝업 -->
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
import {Html5QrcodeScanner} from "html5-qrcode"

export default {
  name: "PopupWithdraw",

  data() {
    return {
      show: false,
      isOtpSet: false,
      withdrawInfo: {
        addressTo: '',
        amount: '',
        phoneCode: '',
        otpCode: ''
      },
      fee: {
        internalFee: '',
        internalFeeType: false,
        externalFee: '',
        externalFeeType: false
      },
      isOTPAuth: false,
      qrScanner: false,
      html5QrcodeScanner: null,
    }
  },

  props: [
    'availableBalance'
  ],

  mounted() {
    window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
  },

  beforeUnmount() {
    window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
  },

  methods: {
    onScanSuccess(decodedText) {
      this.setQr({
        detail: decodedText
      })

      this.closeQR()
    },

    openQR() {
      this.qrScanner = true

      if(this.html5QrcodeScanner !== null) {
        this.html5QrcodeScanner.clear()

        delete this.html5QrcodeScanner
      }

      this.html5QrcodeScanner = new Html5QrcodeScanner("webQR", {fps: 10, qrbox: {width: 250, height: 250}}, false);
      this.html5QrcodeScanner.render(this.onScanSuccess);

      this.openQRReader('QR스캔')
      this.setQRString()
      this.window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
    },
    closeQR() {
      if(this.html5QrcodeScanner !== null) {
        this.html5QrcodeScanner.clear()

        delete this.html5QrcodeScanner
      }
      this.qrScanner = false
    },

    setQr(e) {
      let qr = e.detail
      this.withdrawInfo.addressTo = qr
    },

    closePopup() {
      this.closeQR()

      this.show = false
      this.$emit('closePopup')

      this.withdrawInfo = {
        addressTo: '',
        amount: '',
        phoneCode: '',
        otpCode: ''
      }
    },

    verifyPhone() {
      this.$get(this.$MAIN_WITHDRAW_SMS, 'popupWithdrawPostSMS', true, (result) => {
        console.log(result)
        this.$notify({ type: 'success', text: '문자가 전송되었습니다.' })
      }, (result) => {
        console.log(result)
      })
    },

    showPopup() {
      this.show = true
      if(this.show) {
        this.$get(this.$MAIN_DECIMAL_VIEW, 'mainDecimalView', true, (result) => {
        this.fee = result.data[0]
        }, (result) => {
          console.log(result)
        })

        this.getUserProfile((result) => {
          if(result.success) {
            this.isOTPAuth = result.userProfile.isOTPAuth
          } else {
            console.log(result.userProfile)
          }
        })
      }
    },

    goCheckPin() {
      if(this.availableBalance < this.withdrawInfo.amount) {
        this.$notify({ type: 'error', text: '잔고가 부족합니다.' })
        return
      }

      if(this.withdrawInfo.addressTo && this.withdrawInfo.amount && (this.withdrawInfo.phoneCode || this.withdrawInfo.otpCode)) {
        this.$router.push({
          name: 'Pin',
          params: {
            addressTo: this.withdrawInfo.addressTo,
            amount: this.withdrawInfo.amount,
            phoneCode: this.withdrawInfo.phoneCode,
            otpCode: this.withdrawInfo.otpCode,
            isPinSet: 2 }
        })
      } else {
        this.$notify({ type: 'error', text: '빈 칸을 입력해주세요.' })
      }
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 390px) {
  .info_desc_1 {
    font-size: 1.2rem;
  }
}

.btn_qrcode {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
}
</style>