import { createRouter, createWebHashHistory } from 'vue-router'

import Dashboard from "../components/Dashboard"
import Login from "../components/Login"
import Pin from "../components/Pin"
import MypagePinReset from '../components/mypage/MypagePinReset'
import MypageLoginHistory from '../components/mypage/MypageLoginHistory'
import MypageMain from '../components/mypage/MypageMain'
import MypageOTPSetting from '../components/mypage/MypageOTPSetting'
import MypagePasswordReset from '../components/mypage/MypagePasswordReset'
import PasswordFind from '../components/PasswordFind'
import Signup from '../components/Signup'
import DocUse from '../components/doc/DocUse'
import DocPrivacy from '../components/doc/DocPrivacy'
import LockupList from "@/components/lockup/LockupList";
import LockupDetail from "@/components/lockup/LockupHistory";

export const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        // {
        //     path: '/',
        //     name: 'Intro',
        //     component: Intro
        // },
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/pin',
            name: 'Pin',
            component: Pin
        },

        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
            path: '/mypage/login/history',
            name: 'MypageLoginHistory',
            component: MypageLoginHistory
        },
        {
            path: '/mypage/pin/reset',
            name: 'MypagePinReset',
            component: MypagePinReset
        },
        {
            path: '/mypage/main',
            name: 'MypageMain',
            component: MypageMain
        },
        {
            path: '/mypage/otp/setting',
            name: 'MypageOTPSetting',
            component: MypageOTPSetting
        },
        {
            path: '/mypage/password/reset',
            name: 'MypagePasswordReset',
            component: MypagePasswordReset
        },
        {
            path: '/password/find',
            name: 'PasswordFind',
            component: PasswordFind
        },
        {
            path: '/signup',
            name: 'Signup',
            component: Signup
        },
        {
            path: '/doc/use',
            name: 'DocUse',
            component: DocUse
        },
        {
            path: '/doc/privacy',
            name: 'DocPrivacy',
            component: DocPrivacy
        },
        {
            path: '/dashboard/lockup',
            name: 'LockupList',
            component: LockupList
        },
        {
            path: '/dashboard/lockup/:idx',
            name: 'LockupDetail',
            component: LockupDetail
        },
        // {
        //     path: '/otc/register',
        //     name: 'OfferRegister',
        //     component: OfferRegister,
        //     meta: { keepAlive: true }
        // },
  ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    }
})


