<template>
  <div class="wrap">
    <!-- 해더 -->
    <div class="header clear">
      <div class="left" @click="$router.back()">
        <button><i class="icon-arrow-left"></i> </button>
      </div>
    </div>
    <!-- //해더 -->
    <div class="layout_topBottom">
      <div class="container">
        <div class="row_25">
          <!-- OTP 등록 -->
          <div class="tit_group">
            <h2 class="title">OTP 등록</h2>
            <p class="tit_desc">입출금시 휴대폰 인증 번호 대신 OTP 인증번호를 사용하게 됩니다.</p>
          </div>
          <!-- //OTP 등록 -->
          <hr class="hr_lg"><!-- 구분선 -->
          <!-- 다운로드순서 -->
          <div class="content_group">
            <!-- 1단계 -->
            <div class="step_tit">
              <span class="tit">1단계 OTP앱 다운로드</span>
              <p class="desc">
                시간 동기 (Time OTP)방식을 사용하는 OTP 인증 앱을 스마트폰에 다운로드합니다.
              </p>
            </div>
            <!-- //1단계 -->
            <!-- 1단계-안드로이드 -->
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">안드로이드</span>
                <p class="desc">플레이스토어에서 검색하세요.</p>
              </div>
              <div class="step_item_block">
                <span class="img_otp"><em class="hide">플레이스토어 OTP</em></span>
              </div>
              <div class="step_item_block">
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ko" target="_blank" class="one_btn outline_btn radius_l l_btn">플레이스토어 OTP</a>
              </div>
            </div>
            <!-- //1단계-안드로이드 -->
            <!-- 1단계-아이폰 -->
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">아이폰</span>
                <p class="desc">앱스토어에서 검색하세요.</p>
              </div>
              <div class="step_item_block">
                <span class="img_otp"><em class="hide">앱스토어 OTP</em></span>
              </div>
              <div class="step_item_block">
                <a href="https://apps.apple.com/kr/app/google-authenticator/id388497605" target="_blank" class="one_btn outline_btn radius_l l_btn">앱스토어 OTP</a>
              </div>
            </div>
            <!-- //1단계-아이폰 -->
            <!-- 1단계-Google OTP 앱 신규 사용자 -->
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">Google OTP 앱 신규 사용자</span>
              </div>
              <div class="step_item_block">
                <div class="google_img">
                  <span class="google_new_otp"><em class="hide">앱스토어 OTP</em></span>
                </div>
              </div>
            </div>
            <!-- //1단계-Google OTP 앱 신규 사용자 -->
            <!-- 1단계-Google OTP 앱 기존 사용자 -->
            <div class="step_items">
              <div class="step_item_block step_item_tit">
                <span class="tit">Google OTP 앱 기존 사용자</span>
              </div>
              <div class="step_item_block">
                <div class="google_img">
                  <span class="google_otp"><em class="hide">앱스토어 OTP</em></span>
                </div>
              </div>
            </div>
            <!-- //1단계-Google OTP 앱 기존 사용자 -->
          </div>
          <!-- //다운로드순서 -->
          <hr class="hr_lg"><!-- 구분선 -->
          <div class="content_group">
            <!-- 2단계 -->
            <div class="step_tit">
              <span class="tit">2단계 QR코드 인증</span>
              <p class="desc">
                2단계 인증 모바일 앱을 실행하여 아래의 QR코드를 스캔하세요.
              </p>
            </div>
            <!-- //2단계 -->
            <!-- 2단계 QR -->
            <div class="step_items">
              <div class="step_item_block">
                <div class="qr_zone">
<!--                  <img src="/assets/image/@qr_code_200.png" alt="임시QR이미지">-->
                  <qrcode-vue class="qr_padding" :value="otpUrl" :size="size" level="H"/>
                  <!-- TODO : canvas 200px 고정 -->
<!--                   <canvas width="100" height="100"></canvas>-->
                </div>
              </div>
              <div class="step_item_block">
                <div class="step_descript">
                  <p class="desc">만약 모바일 앱이 QR코드를 지원하지 않는다면, 아래 코드를 직접 입력하세요.</p>
                  <span class="code">코드 : <b>{{ otpKey }}</b></span>
                </div>
              </div>
            </div>
            <!-- //2단계 QR -->
          </div>
          <hr class="hr_lg"><!-- 구분선 -->
          <div class="content_group">
            <!-- 3단계 -->
            <div class="step_tit">
              <span class="tit">3단계 OTP 인증</span>
              <p class="desc">
                QR코드 스캔 후 OTP에 표시되는 코드를 입력하세요.
                <em class="ex_code">Meterra_({{ email }})</em>
              </p>
            </div>
            <!-- //3단계 -->
            <!-- 3단계 OTP인증 -->
            <div class="step_items">
              <div class="step_item_block">
                <div class="basic_input btn_input_2">
                  <input type="number" placeholder="코드를 입력" v-model="otpCode" />
                  <button class="l_btn radius_l fill_btn_bk" @click="verify">인증</button>
                </div>
              </div>
            </div>
            <!-- //3단계 OTP인증 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "MypageOTPSetting",

  data() {
    return {
      otpKey: '',
      otpUrl: '',
      size: 200,
      email: '',
      otpCode: '',
    }
  },

  components: {
    QrcodeVue,
  },

  created() {
    this.$get(this.$MYPAGE_OTP, 'getMypageOTP', true, (result) => {
      console.log(result)
      this.otpKey = result.data[0].otpKey
      this.otpUrl = result.data[0].otpUrl
    }, (result) => {
      console.log(result)
    })

    this.getUserProfile((result) => {
      if(result.success) {
        this.email = result.userProfile.email
      } else {
        console.log(result.userProfile)
      }
    })
  },

  methods: {
    verify() {
      if(!this.otpCode) {
        this.$notify({ type: 'error', text: '코드를 입력해주세요.' })
        return
      }

      let formData = new FormData()
      formData.append('otpCode', this.otpCode)
      formData.append('key', this.otpKey)

      this.$post(this.$MYPAGE_OTP_AUTH, 'mypageOTPAuth', formData, true, (result) => {
        this.$notify({ type: 'success', text: result.message })
        this.$router.back()
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>