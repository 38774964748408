<template>
  <div class="wrap">
      <!-- 텝영역 -->
      <div style="position: fixed; width: 100%; background: white; z-index: 9">
      <div class="tablist pt-50">
        <ul class="tab_type1">
          <li class="tab_type1-item" :class="{'selected':listType === 'MY'}"><a role="button" class="item_link" @click="tabBtn('MY')">MY 락업</a></li>
          <li class="tab_type1-item" :class="{'selected':listType === 'HISTORY'}"><a role="button" class="item_link" @click="tabBtn('HISTORY')">내역</a></li>
        </ul>
      </div>
      <!-- //텝영역 -->
    </div>
    <!-- 마이락업 목록내역 -->
    <div class="contents h102 scroll_list" v-if="listType==='MY'" @scroll="handleHistoryListScroll">
      <div class="container pt-110">
        <div class="row_25">
          <div class="none_content" v-if="myLockupList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>진행중인 내역이 없습니다.</p>
          </div>
          <ul class="gallery pt-30 pb-90" v-else>
            <li v-for="(list, index) in myLockupList" :key="index"> <div class="gallery__content opacity">
              <div class="gallery__title">
                <div class="img_box title">
                  <span class="text white">{{ list.lockupPlanName }}</span>
                </div>
                <div class="title">
                  <!--                    <span class="text white">{{ list.lockupPlanName }}123123</span>-->
                </div>
              </div>
<!--              <div class="gallery__content opacity">-->
                <table class="bbs__view underline type_1">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
<!--                  <tr>-->
<!--                    <th>락업명</th>-->
<!--                    <td><span>{{ list.lockupPlanName }}</span></td>-->
<!--                  </tr>-->
                  <tr>
                    <th>락업수량</th>
                    <td><span>{{ numberWithCommas(Number(list.lockupAmount)) }}</span> {{ getCoinLabel() }}</td>
                  </tr>
                  <tr>
                    <th>락업기간</th>
                    <td>{{ formatDate(list.lockupStartDate, 'date') }} ~ {{ formatDate(list.lockupExpiryDate, 'date') }}</td>
                  </tr>
                  <tr>
                    <th>지급비율</th>
                    <td><span>{{ `${100/list.lockupRatio}% / ${list.lockupLatency}Day` }}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //마이락업 목록내역 -->
    <!-- 목록내역 -->
    <div class="contents h154 scroll_list" v-if="listType==='HISTORY'" @scroll="handleHistoryListScroll">
      <div class="container pt-110">
        <div class="row_25">
          <div class="none_content" v-if="luHistoryList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>진행중인 내역이 없습니다.</p>
          </div>
          <ul class="gallery pt-30 pb-100" v-else>
            <li v-for="(list, index) in luHistoryList" :key="index" @click="$router.push({ name: 'LockupDetail', params: { idx: list.lockupIdx } })">
              <div class="gallery__content opacity">
                <div class="gallery__title">
                  <div class="img_box title">
                    <span class="text white">{{ list.lockupPlanName }}</span>
                  </div>
                  <div class="title">
<!--                    <span class="text white">{{ list.lockupPlanName }}123123</span>-->
                  </div>
                  <div class="desc_area">
                    <span class="status type2" :class="{'type1' : list.status === 'CANCEL', 'type3': list.status === 'PROCESSING'}">{{ trans(list.status) }}</span>
                  </div>
                </div>
                <table class="bbs__view underline type_1">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>락업수량</th>
                    <td><span>{{ numberWithCommas(Number(list.lockupAmount)) }}</span> {{ getCoinLabel() }}</td>
                  </tr>
                  <tr>
                    <th>지급된 수량</th>
                    <td><span>{{ numberWithCommas(Number(list.lockupAmount)-Number(list.lockupRemain)) }}</span> {{ getCoinLabel() }}</td>
                  </tr>
                  <tr>
                    <th>락업기간</th>
                    <td>{{ formatDate(list.lockupStartDate, 'date') }} ~ {{ formatDate(list.lockupExpiryDate, 'date') }}</td>
                  </tr>
                  <tr>
                    <th>지급비율</th>
                    <td><span>{{ `${100/list.lockupRatio}% / ${list.lockupLatency}Day` }}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //목록내역 -->
  </div>
</template>

<script>
export default {
  name: "LockupList",
  props: ['setHeader'],
  data(){
    return {
      listType: this.$route.query.listType ? this.$route.query.listType : 'MY',
      page: 1,
      limit: 5,
      myLockupList: [],
      luHistoryList: [],
      year: '',
      lockupDetail: []
    }
  },
  mounted() {
    this.setHeader('dashboard', '락업')
    let d = new Date()
    this.year = d.getFullYear()
    if(this.listType === 'MY'){
      this.getMyLockupList()
    } else {
      this.getLUHistoryList()
    }
  },
  methods: {
    tabBtn(type){
      this.page = 1
      if( type === this.listType){
        return
      }
      if(type === 'MY'){
        this.getMyLockupList()
      } else {
        this.getLUHistoryList()
      }
    },
    handleHistoryListScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight;
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.handleLoadMore();
    },
    handleLoadMore() {
      if(this.listType === 'MY'){
        if (this.myLockupList.length < this.total) {
          this.page = this.page + 1
          this.getMyLockupList()
        }
      } else {
        if (this.luHistoryList.length < this.total) {
          this.page = this.page + 1
          this.getLUHistoryList()
        }
      }
    },
    queryChange(){
      this.$router.push({
        query: {listType: this.listType},
      });
    },
    setDate(index) {
      let date = this.formatDate(this.luHistoryList.lockupStartDate, 'month')
      index--
      if (index == -1) {
        return false
      }
      let date2 = this.formatDate(this.luHistoryList.lockupStartDate, 'month')
      let result = date == date2
      return result
    },
    trans(value) {
      if (value === 'CANCEL') {
        return '중도해지'
      } else if (value === 'DONE') {
        return '종료'
      } else if (value === 'PROCESSING') {
        return '지급중'
      }
    },
    getLUHistoryList(){
      this.myLockupList =[]
      this.listType='HISTORY'
      let formData = new FormData()
      formData.append('year', this.year)
      formData.append('page', this.page)
      formData.append('limit', this.limit)
      this.$get(`${this.$LOCKUP_HISTORY}?${new URLSearchParams(formData).toString()}`, 'get_my_lockup', true, (result) => {
        console.log(result.data)
        this.total = result.total
        for (let i = 0; i < result.data.length; i++) {
          this.luHistoryList.push(result.data[i])
        }
        this.queryChange()
      }, (result) => {
        this.httpError(result)
      })
    },
    getMyLockupList(){
      this.luHistoryList =[]
      let d = new Date()
      this.year = d.getFullYear()
      this.listType='MY'
      let formData = new FormData()
      formData.append('page', this.page)
      formData.append('limit', this.limit)
      this.$get(`${this.$LOCKUP_MY}?${new URLSearchParams(formData).toString()}`, 'get_my_lockup', true, (result) => {
        console.log(result)
        this.total = result.total
        for (let i = 0; i < result.data.length; i++) {
          this.myLockupList.push(result.data[i])
        }
        this.queryChange()
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>
.scroll_list{
  -ms-overflow-style: none;
}
.scroll_list::-webkit-scrollbar{
  display:none;
}
.scroll_list{
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}
.none_content{
  height: calc(100vh - 154px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.none_content p {
  font-size: 16px;
  text-align: center;
  margin: 12px;
  color: white;
}
</style>