<template>
  <div class="wrap">
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">
            <div class="tit_area">
              <h2 class="title">비밀번호 찾기</h2>
            </div>
            <div class="content_area">
              <div class="group">
                <p class="info_desc_3">해당 이메일 주소로 전송된 링크를 통해 비밀번호를 재설정 할 수 있습니다.</p>
              </div>
              <div class="text_field group">
                <div class="basic_input">
                  <input type="email" v-model="email" placeholder="이메일 입력">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <div class="find_wrap">
                <router-link :to="{ name: 'Login' }">로그인</router-link>
                <router-link :to="{ name: 'Signup' }">회원가입</router-link>
              </div>
            </div>
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="sendEmail">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PasswordFind",

  data() {
    return {
      email: '',
    }
  },

  props: ['setHeader'],

  mounted() {
    this.setHeader('close')
  },

  methods: {
    sendEmail() {
      let formData = new FormData()
      formData.append('email', this.email)

      this.$post(this.$USER_FIND_PASSWORD, 'userFindPwd', formData, false, () => {
        this.$notify({ type: 'success', text: '비밀번호 변경 링크를 메일로 발송했습니다.' })
      }, (result) => {
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>