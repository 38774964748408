import { createApp } from 'vue'
import { router } from './router/index'
import App from './App.vue'
import '@fortawesome/fontawesome-free/js/all.js'
import Notifications from '@kyvg/vue3-notification'
import axios from "axios";

const app = createApp(App)

app.use(router)
app.use(Notifications)

router.isReady().then(() => {
  app.mount('#app')
})

//-------------- 전역 변수 및 함수--------------------------------------------
let requestOption = () => {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 60000
  }
}
let requestOptionWithToken = () => {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
    },
    timeout: 60000
  }
}

app.config.globalProperties.getCoinLabel = () => {
  return process.env.VUE_APP_COIN_LABEL
}

let pending_get = {}
let pending_post = {}

app.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN
app.config.globalProperties.$USER_LOGIN = "/user/login"
app.config.globalProperties.$USER_REFRESH_TOKEN = "/user/refresh/token"
app.config.globalProperties.$USER_SIGNUP = "/user/signup"
app.config.globalProperties.$USER_SIGNUP_SMS = "/user/signup/sms"
app.config.globalProperties.$USER_SIGNUP_SMS_VERIFY = "/user/signup/sms/verify"
app.config.globalProperties.$USER_SIGNUP_EMAIL = "/user/signup/email"
app.config.globalProperties.$USER_SIGNUP_EMAIL_VERIFY = "/user/signup/email/verify"
app.config.globalProperties.$USER_PIN_AUTH = "/user/pin/auth"
app.config.globalProperties.$USER_PIN_INIT = "/user/pin/init"
app.config.globalProperties.$USER_FIND_PASSWORD = "/user/find/password"
app.config.globalProperties.$MAIN_LOCKUP_AMOUNT = "/main/lockup/amount"
app.config.globalProperties.$MAIN_DECIMAL_VIEW = "/main/decimal/view"
app.config.globalProperties.$MAIN_MYBALANCE_AMOUNT = "/main/myBalance/amount"
app.config.globalProperties.$MAIN_WALLET_HISTORY = "/main/wallet/history"
app.config.globalProperties.$USER_PROFILE = "/user/profile"
app.config.globalProperties.$MAIN_WITHDRAW = "/main/withdraw"
app.config.globalProperties.$MAIN_WITHDRAW_SMS = "/main/withdraw/sms"
app.config.globalProperties.$MAIN_LOCKUP_DETAIL = "/main/lockup/detail"
app.config.globalProperties.$MYPAGE_PASSWORD_RESET = "/mypage/password/reset"
app.config.globalProperties.$MYPAGE_PASSWORD_FIND = "/mypage/password/find"
app.config.globalProperties.$MYPAGE_LOGIN_HISTORY = "/mypage/login/history"
app.config.globalProperties.$MYPAGE_PIN_RESET = "/mypage/pin/reset"
app.config.globalProperties.$MYPAGE_OTP = "/mypage/otp"
app.config.globalProperties.$MYPAGE_OTP_AUTH = "/mypage/otp/auth"
app.config.globalProperties.$MYPAGE_LOGOUT = "/mypage/logout"
app.config.globalProperties.$LOCKUP_MY = "/lockup/my"
app.config.globalProperties.$LOCKUP_HISTORY = "/lockup/history"

app.config.globalProperties.$post = async function (callUrl, caller, postData, useToken, success, fail){
  console.log(arguments[0])
  if (pending_post[arguments[0] + caller]) {
    return
  }
  pending_post[arguments[0] + caller] = true
  let _requestOption = requestOption()
  if (useToken) {
    let token = sessionStorage.getItem('accessToken')
    if (token && token.length > 0) {
      _requestOption = requestOptionWithToken()
    } else {
      this.$router.replace({ name: 'Login' })
    }
  }
  axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
    pending_post[arguments[0] + caller] = false
    if(!response.data.result) {
      this.$notify({ type: 'error', text: response.data.message })
      return
    }
    success(response.data)
  }).catch(e => {
    pending_post[arguments[0] + caller] = false
    console.log(e)
    fail(e.response.data)

    if(e.response.data.status === 403 || e.response.data.status === 401) {
      this.$router.replace({name: 'Login'})
      sessionStorage.removeItem('accessToken')
      this.$store.state.isLoggedIn = false
    }

  })
}

app.config.globalProperties.$get = async function (callUrl, caller, useToken, success, fail){
  console.log(arguments[0])
  if (pending_get[arguments[0] + caller]) {
    return
  }
  pending_get[arguments[0] + caller] = true
  let _requestOption = requestOption()
  if (useToken) {
    let token = sessionStorage.getItem('accessToken')
    if (token && token.length > 0) {
      _requestOption = requestOptionWithToken()
    } else {
      this.$router.replace({name: 'Login'})
    }
  }
  axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
    pending_get[arguments[0] + caller] = false
    if(!response.data.result) {
      this.$notify({ type: 'error', text: response.data.message })
      return
    }
    success(response.data)
  }).catch(e => {
    pending_get[arguments[0] + caller] = false
    fail(e.response.data)
    if(e.response.data.status === 403 || e.response.data.status === 401) {
      this.$router.replace({name: 'Login'})
      sessionStorage.removeItem('accessToken')
      this.$store.state.isLoggedIn = false
    }
  })
}

//------------------------------------------------------------------------------------------

app.config.globalProperties.refreshTokenRate = 5*60*1000
app.config.globalProperties.refreshToken = function() {
  let refreshToken = sessionStorage.getItem('refreshToken')
  let accessToken = sessionStorage.getItem('accessToken')

  if(!refreshToken) {
    return
  }
  let formData = new FormData()
  formData.append('refreshToken', refreshToken)

  this.$post(this.$USER_REFRESH_TOKEN, 'refreshToken', formData, false, (result) => {
    if(accessToken !== result.data[0].accessToken) {
      sessionStorage.setItem('accessToken', result.data[0].accessToken)
      sessionStorage.setItem('refreshToken', result.data[0].refreshToken)
    }

    setTimeout(() => {
      this.refreshToken()
    }, this.refreshTokenRate)
  }, () => {
    this.$router.push({ name: 'Login' }).catch(() => {})
    this.$notify({ type: 'error', text: '로그인 토큰이 만료되었습니다.' })
  })
}

app.config.globalProperties.numberWithCommasAndDecimal = function (x) {
  let decimalView = sessionStorage.getItem('decimalView')
  if(!decimalView) {
    decimalView = 9
  }
  let zeros = ''
  for(let i=0; i<decimalView; i++) {
    zeros += '0'
  }

  if(!(x+'').includes('.')) {
    return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + zeros
  } else {
    let parts = x.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    parts[1] = parts[1] + zeros
    parts[1] = parts[1].substr(0, decimalView)
    return parts.join('.')
  }
}

app.config.globalProperties.numberWithCommas = function (x) {
  if(!(x+'').includes('.')) {
    return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return `${((x+'').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(x+'').split('.')[1]}`
  }
}

app.config.globalProperties.getUserProfile = function (callback) {
  this.$get(this.$USER_PROFILE, 'GetUserProfile' + Math.random(), true, (result) => {
    callback({ success: true, userProfile: result.data[0] })
  }, (result) => {
    callback({ success: false, userProfile: result })
  })
}

app.config.globalProperties.formatDate = function(timeStamp, dateType = 'full') {
  function makeTwoDigits(number) {
    return number >= 10 ? number : '0' + number
  }

  let d = new Date(timeStamp)
  let year = d.getFullYear()
  let month = makeTwoDigits(d.getMonth() + 1)
  let date = makeTwoDigits(d.getDate())
  let hour = makeTwoDigits(d.getHours())
  let min = makeTwoDigits(d.getMinutes())
  let sec = makeTwoDigits(d.getSeconds())
  if(dateType === 'full') {
    return `${year}-${month}-${date} ${hour}:${min}:${sec}`
  } else if(dateType === 'date'){
    return `${year}-${month}-${date}`
  } else if(dateType === 'month'){
    return `${year}. ${month}`
  }  else if(dateType === 'date2'){
    return `${year}.${month}.${date}`
  } else {
    return `${hour}:${min}:${sec}`
  }
}

app.config.globalProperties.httpError = function(data) {
  if(data.error[0]) {
    this.$notify({ type: 'error', 'text': data.error[0].message })
  } else {
    this.$notify({ type: 'error', 'text': data.message })
  }
}

// ---------------app----------------------------------------------------------------------------
app.config.globalProperties.window = window
app.config.globalProperties.$EVENT_QR_STRING = "$EVENT_QR_STRING"
app.config.globalProperties.openQRReader = (_type ) => {
  console.log(_type)
}
app.config.globalProperties.setQRString = (_qrstr = '') => {
  var event = new CustomEvent('$EVENT_QR_STRING', {'detail': _qrstr});
  window.dispatchEvent(event);
}
