<template>
  <div class="wrap">
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">
            <!-- 비밀번호재설정 -->
            <div class="tit_area">
              <h2 class="title">비밀번호 재설정</h2>
            </div>
            <!-- //비밀번호재설정 -->
            <!-- 비밀번호 재설정 폼 -->
            <div class="content_area">
              <div class="text_field group">
                <div class="basic_input" v-show="!verifyCode">
                  <input type="password" v-model="password" placeholder="현재 비밀번호">
                </div>
                <div class="basic_input">
                  <input type="password" v-model="newPassword" placeholder="새로운 비밀번호">
                </div>
                <div class="basic_input">
                  <input type="password" v-model="newPasswordConfirm" placeholder="비밀번호 확인">
                </div>
              </div>
              <div class="group">
                <p class="info_desc">8~16자 영문 대 소문자, 숫자, 특수문자 사용</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //비밀번호 재설정 폼 -->
      <!-- 버튼영역 -->
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="verifyCode ? resetPassword() : changePassword()" >비밀번호 변경</button>
            </div>
          </div>
        </div>
      </div>
      <!-- //버튼영역 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordReset",

  data() {
    return {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
      verifyCode: '',
    }
  },

  props: ['setHeader'],

  created() {
    let hash = window.location.hash
    this.verifyCode = hash.split('=')[1]
  },

  mounted() {
    this.setHeader('arrow')
  },

  methods: {
    resetPassword() {
      if(!this.newPassword || !this.newPasswordConfirm) {
        this.$notify({ type: 'error', 'text': '빈 칸을 입력해주세요.' })
        return
      }

      if(this.newPassword !== this.newPasswordConfirm) {
        this.$notify({ type: 'error', 'text': '새 비밀번호가 일치하지 않습니다.' })
        return
      }

      let formData = new FormData()
      formData.append('newPassword', this.newPassword)
      formData.append('verifyCode', this.verifyCode)

      this.$post(this.$MYPAGE_PASSWORD_FIND, 'postMypagePasswordFind', formData, false, (result) => {
        this.$notify({ type: 'success', 'text': result.message })
        this.$router.push({ name: 'Login' })
      }, (result) => {
        this.httpError(result)
      })
    },

    changePassword() {
      if(!this.password || !this.newPassword || !this.newPasswordConfirm) {
        this.$notify({ type: 'error', 'text': '빈 칸을 입력해주세요.' })
        return
      }

      if(this.newPassword !== this.newPasswordConfirm) {
        this.$notify({ type: 'error', 'text': '새 비밀번호가 일치하지 않습니다.' })
        return
      }

      let formData = new FormData()
      formData.append('password', this.password)
      formData.append('newPassword', this.newPassword)

      this.$post(this.$MYPAGE_PASSWORD_RESET, 'postMypagePasswordReset', formData, true, (result) => {
        this.$notify({ type: 'success', 'text': result.message })
        this.$router.push({ name: 'Login' })
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>