<template>
  <div class="wrap">
    <div class="container">
<!--      <Header :headerBtn="headerBtn"></Header>-->
      <div class="layout_topBottom">
        <div class="row_25">
          <!-- 회원가입 -->
          <div class="tit_area">
            <h2 class="title">회원가입</h2>
          </div>
          <!-- //회원가입 -->
          <!-- 회원가입 폼 -->
          <div class="content_area">
            <div class="text_field group">
              <div class="basic_input btn_input_2">
                <input type="text" v-model="email" placeholder="이메일 입력">
                <button class="l_btn radius_l fill_btn_bk" @click="verifyEmail">인증</button>
              </div>
              <div class="basic_input btn_input_2">
                <input type="text" v-model="emailCode" placeholder="인증번호 입력">
                <button class="l_btn radius_l fill_btn_bk" @click="chkEmailCode">확인</button>
              </div>
              <div class="basic_input">
                <input type="text" v-model="name" placeholder="이름 입력">
              </div>
              <div class="basic_input">
                <input type="password" v-model="password" placeholder="비밀번호(영문, 숫자, 특수문자 포함 8자 이상)">
              </div>
              <div class="basic_input">
                <input type="password" v-model="passwordConfirm" placeholder="비밀번호 재입력">
              </div>
              <div class="basic_input btn_input_2">
                <input class="pl-50" type="text" v-model="phone" placeholder="핸드폰번호(-제외) 입력">
                <span class="country" @click="openCountries = !openCountries">{{ selectedCountryCode }}</span>
                <ul v-show="openCountries">
                  <li v-for="(country, countryIdx) in countries" :key="countryIdx" @click="selectCountry(country)">{{ country }}</li>
                </ul>
                <button class="l_btn radius_l fill_btn_bk" @click="verifyPhone">인증</button>
              </div>
              <div class="basic_input btn_input_2">
                <input type="text" v-model="phoneCode" placeholder="인증번호 입력">
                <button class="l_btn radius_l fill_btn_bk" @click="chkPhoneCode">확인</button>
              </div>
            </div>
            <div class="group">
              <div class="corp_area">
                <p>회원가입시
                  <router-link :to="{ name: 'DocUse' }">이용약관</router-link> 및
                  <router-link :to="{ name: 'DocPrivacy' }">개인정보 처리방침</router-link>에 동의하게 됩니다.
                </p>
              </div>
            </div>
          </div>
          <!-- //회원가입 폼 -->
          <!-- 회원가입 버튼 -->
          <div class="base_area">
            <button class="one_btn l_btn fill_btn_bk radius_l" @click="signup">회원가입</button>
          </div>
          <!-- //회원가입 버튼 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "VueSignup",

  data() {
    return {
      openCountries: false,
      email: '',
      emailCode: '',
      name: '',
      password: '',
      passwordConfirm: '',
      phone: '',
      phoneCode: '',
      emailVerifyKey: '',
      phoneVerifyKey: '',
      countries: {},
      selectedCountryCode: '+82',
    }
  },

  props: ['setHeader'],

  created() {
    let countryCodes = require('country-codes-list')
    let countryCodesObj = countryCodes.customList('countryNameEn', '+{countryCallingCode}({countryNameEn})')
    this.countries = Object.values(countryCodesObj)
  },

  mounted() {
    this.setHeader('close')
  },

  methods: {
    selectCountry(country) {
      let arr = country.split('(')
      this.selectedCountryCode = arr[0]
      this.openCountries = false
    },

    chkEmailCode() {
      let formData = new FormData()
      formData.append('id', this.email)
      formData.append('code', this.emailCode)

      this.$post(this.$USER_SIGNUP_EMAIL_VERIFY, 'userSignupEmailCode', formData, false, (result) => {
        console.log(result)
        this.emailVerifyKey = result.data[0].verifyKey
        this.$notify({ type: 'success', text: '확인되었습니다.' })
      }, (result) => {
        console.log(result)
      })
    },

    chkPhoneCode() {
      let formData = new FormData()
      formData.append('id', this.selectedCountryCode + this.phone)
      formData.append('code', this.phoneCode)

      this.$post(this.$USER_SIGNUP_SMS_VERIFY, 'userSignupPhoneCode', formData, false, (result) => {
        console.log(result)
        this.phoneVerifyKey = result.data[0].verifyKey
        this.$notify({ type: 'success', text: '확인되었습니다.' })
      }, (result) => {
        console.log(result)
      })
    },

    verifyPhone() {
      let formData = new FormData()
      formData.append('phone', this.selectedCountryCode + this.phone)

      this.$post(this.$USER_SIGNUP_SMS, 'userSignupPhone', formData, false, (result) => {
        console.log(result)
        this.$notify({ type: 'success', text: result.message })
      }, (result) => {
        console.log(result)
        this.$notify({ type: 'error', text: result.message })
      })
    },

    verifyEmail() {
      let formData = new FormData()
      formData.append('email', this.email)

      this.$post(this.$USER_SIGNUP_EMAIL, 'userSignupEmail', formData, false, (result) => {
        console.log(result)
        this.$notify({ type: 'success', text: result.message })
      }, (result) => {
        console.log(result)
        this.$notify({ type: 'error', text: result.message })
      })
    },

    signup() {
      if(!this.name || !this.password) {
        this.$notify({ type: 'error', text: '빈 칸을 입력해주세요.' })
        return
      }

      if(this.password !== this.passwordConfirm) {
        this.$notify({ type: 'error', text: '비밀번호가 맞지 않습니다.' })
        return
      }

      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('password', this.password)
      formData.append('emailVerifyKey', this.emailVerifyKey)
      formData.append('phoneVerifyKey', this.phoneVerifyKey)

      this.$post(this.$USER_SIGNUP, 'postSignup', formData,false, (result) => {
        this.$notify({ type: 'success', text: result.message })
        this.$router.push({ name: 'Login' })
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>
.basic_input .country {
  position: absolute;
  line-height: 48px;
  padding: 1px 20px;
  font-size: 1.4rem;
  color: var(--dark-gray02);
}

ul {
  display: block;
  z-index: 1;
  font-size: 1.4rem;
  border: 1px solid var(--light-gray03);
  border-radius: 30px;
  width: 75%;
  background: #fff;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  left: -1px;
}

li {
  padding: 4px 15px;
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  outline-style: none;
  line-height: 30px !important;
}


</style>