<template>
  <div class="wrap" v-if="history" style="background: white">
    <!-- 해더 -->
    <div class="header clear flex">
      <div class="title"><span>락업 상세</span></div>
      <div class="right">
        <button @click="$router.back()"><i class="icon-close dashboard_color"></i></button>
      </div>
    </div>
    <!-- //해더 -->
    <!-- 메인 -->
    <div class="layout_topBottom">
      <div class="container">
        <!-- 상세내역 -->
        <div class="row_25 mt-10">
            <div class="gallery__title pb-0">
              <div class="img_box title">
                <span class="text white">{{ history.lockupPlanName }}</span>
              </div>
              <div class="title">
                <!--                    <span class="text white">{{ list.lockupPlanName }}123123</span>-->
              </div>
              <div class="desc_area">
                <span class="status type2" :class="{'type1' : history.status === 'CANCEL', 'type3': history.status === 'PROCESSING'}">{{ trans(history.status) }}</span>
              </div>
            </div>
            <table class="bbs__view type_1">
            <colgroup>
              <col style="width: 8rem;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>락업수량</th>
              <td>
                <span>{{ numberWithCommas(Number(history.lockupAmount)) }}</span> {{ getCoinLabel() }}<br>
              </td>
            </tr>
            <tr>
              <th>지급된 수량</th>
              <td>
                <span>{{ numberWithCommas(Number(history.lockupAmount)-Number(history.lockupRemain)) }}</span>{{ getCoinLabel() }}
              </td>
            </tr>
            <tr>
              <th>락업기간</th>
              <td>{{ formatDate(history.lockupStartDate, 'date') }} ~ {{ formatDate(history.lockupExpiryDate, 'date') }}</td>
            </tr>
            <tr>
              <th>지급비율</th>
              <td class="primary">
                <span>{{ `${100/history.lockupRatio}% / ${history.lockupLatency}Day` }}</span><br>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="underline type_2 mt-20" v-if="historyDetail.length > 0">
            <div class="gallery__title">
              <div class="img_box title mt-10">
                <span class="text white">지급 내역</span>
              </div>
              <div class="title">
                <!--                    <span class="text white">{{ list.lockupPlanName }}123123</span>-->
              </div>
            </div>
            <table class="bbs__view type_1">
              <colgroup>
                <col style="width: 8rem;">
                <col>
              </colgroup>
              <tbody>
              <tr v-for="(item, index) in historyDetail" :key="index">
                <th>{{ formatDate(item.createdAt, 'date2') }}</th>
                <td>
                  <span>{{ numberWithCommas(Number(item.amount)) }}</span> {{ getCoinLabel() }}<br>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- //상세내역 -->
      </div>
    </div>
    <!-- 메인 -->
  </div>
</template>

<script>
export default {
  name: "LockupHistory",
  props: ['showHeader'],
  data(){
    return{
      idx: this.$route.params.idx,
      history: [],
      historyDetail: [],
      page: 1,
      limit: 10,
    }
  },
  mounted() {
    this.showHeader(false)
    this.getHistory()
  },
  methods: {
    trans(value) {
      if (value === 'CANCEL') {
        return '중도해지'
      } else if (value === 'DONE') {
        return '종료'
      } else if (value === 'PROCESSING') {
        return '지급중'
      }
    },
    getHistory(){
      this.$get(`${this.$LOCKUP_HISTORY}/${this.idx}`, 'get_lu_history', true, (result) => {
        console.log(result.data[0])
        this.history = result.data[0]
        this.historyDetail = result.data[0].transferList
      },(result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>
.dashboard_color {
  color: var(--dark-gray02)!important;
}
.header .title span {
  color: var(--dark-gray02)!important;
}
</style>