<template>
  <div v-if="show">
    <div class="header clear" v-show="!isDashboard">
      <div :class="{ right: btn === 'close', left: btn === 'arrow' || btn === 'dashboard' }">
        <button>
          <i class="icon-close" v-show="btn === 'close'" @click="$router.push({ name: 'Login' })"></i>
          <i class="icon-arrow-left dashboard_color" v-show="btn === 'dashboard'" @click="$router.push({ name: 'Dashboard' })"></i>
          <i class="icon-arrow-left" v-show="btn === 'arrow'" @click="$router.back()"></i>
        </button>
      </div>
      <div class="title">
        <span v-show="tit !== 'img'" :class="{ dashboard_color : btn === 'dashboard'}">{{ tit }}</span>
        <span v-show="tit === 'img'"><img src="/assets/image/h_logo.png" alt="메테 락업"></span>
      </div>
    </div>

    <div class="home_header clear" v-show="isDashboard">
      <h1 class="logo">
        <img src="/assets/image/h_logo.png" alt="락업로고">
      </h1>
      <span class="mypage">
        <router-link :to="{ name: 'MypageMain' }" class="profile"><img src="/assets/image/@profile.png" alt="프로필"></router-link>
      </span>
    </div>
  </div>

</template>

<script>
export default {
  name: "VueHeader",

  data() {
    return {
      show: true,
      btn: '',
      tit: '',
      isDashboard: false
    }
  },

  methods: {
    showHeader(bool) {
      this.show = bool
    },

    setHeader(btn, tit = '') {
      this.btn = btn
      this.tit = tit
      this.isDashboard = false
      this.show = true
    },

    setDashboardHeader() {
      this.isDashboard = true
    }
  }
}
</script>

<style scoped>
.dashboard_color {
  color: var(--dark-gray02)!important;
}
</style>