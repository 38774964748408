<template>
  <div class="wrap" :class="{ 'auto-height': isLockupDetailOpen || isDepositOpen || isWithdrawOpen }">
    <PopupDeposit ref="PopupDeposit" @closePopup = closePopup></PopupDeposit>
    <PopupLockupDetail ref="PopupLockupDetail" @closePopup = closePopup></PopupLockupDetail>
    <PopupWithdraw ref="PopupWithdraw" @closePopup = closePopup :availableBalance = availableBalance></PopupWithdraw>
    <div>
      <!-- 메인페이지  -->
      <div class="layout_home">
        <div class="container">
          <div class="row_25">
            <h3 class="hide">총 락업 나의 자산 확인</h3>
            <div class="home_lockup">
              <div class="total_lockup">
                <h4 class="subtit color_theme">총 락업</h4>
                <div class="staking">
                  <strong class="num_price">{{ numberWithCommas(lockupBalance) }}<em class="unit">{{ getCoinLabel() }}</em></strong>
<!--                  <span class="rate_result">150,236,000<em class="unit">USD</em></span>-->
                </div>
              </div>
              <hr class="hr_lg_10">
              <div class="assets">
                <h4 class="subtit color_theme">나의 자산</h4>
                <strong class="num_price">{{ numberWithCommas(availableBalance) }}<em class="unit">{{ getCoinLabel() }}</em></strong>
              </div>
              <div class="view_detail" @click="$router.push({ name : 'LockupList' })">
                <button class="fill_btn_pr s_btn radius_s">상세보기</button>
              </div>
            </div>
            <div class="dw_status">
              <h2 class="tit">입출금현황</h2>
              <div class="dw_card_container">
                <div class="dw_card_wrap">
                  <!-- 카드 색션 : 입금 plus 출금 minus-->
                  <div class="dw_card_item plus" :class="{ plus: history.isDeposit, minus: !history.isDeposit }" v-for="history in historyList" :key="history.date">
                    <div class="card_head">
                      <div class="dw_tit clear">
                        <span class="icon_coin">
                            <img src="/assets/image/ico_coin.png" alt="코인로고">
                        </span>
                        <div class="address">
                          <p>{{ history.txid }}</p>
                        </div>
                      </div>
                      <div class="dw_cont">
                        <div class="staking">
                          <strong class="num_price">
                            <em class="unit">{{ history.isDeposit ? '+' : '-' }}</em>{{ numberWithCommasAndDecimal(history.amount) }}
                            <em class="unit">{{ getCoinLabel() }}</em>
                          </strong>
<!--                          <span class="rate_result"><em class="unit">$</em>150,236,000<em class="unit">USD</em></span>-->
                        </div>
                        <div class="badge">
                          <span>{{ history.typeTrans }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card_body">
                      <div class="dw_date">
                        <span class="date">{{ history.formatDate }}</span>
                        <button class="tg_btn" @click="history.isTxOpen = !history.isTxOpen; rerenderKey += 1">
                          TXID
                          <i class="icon-arrow-up" v-show="history.isTxOpen"></i>
                          <i class="icon-arrow-down" v-show="!history.isTxOpen"></i>
                        </button>
                      </div>
                      <div class="dw_box" v-show="history.isTxOpen" :key="rerenderKey">
                        <p class="txid_hash">
                          <b>Transaction Hash</b>
                          <span>{{ history.txid ? history.txid : history.type === 'HOUSE_IN' || history.type === 'HOUSE_OUT' ? '내부 전송' : '' }}</span>
                        </p>
                        <p class="txid_status" v-show="history.isDeposit">
                          <b>Status</b>
                          <span>{{ history.confirm }} Confirmations</span>
                        </p>
                        <p class="txid_address" v-show="!history.isDeposit">
                          <b>Wallet Address</b>
                          <span class="w_copy">{{ history.addressTo }}<button class="link_copy" @click="copyAddress(history.addressTo)"><em class="hide">주소복사</em></button></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //카드 영역 -->
                <!-- 더보기 -->
                <div class="card_more">
                  <button @click="limit += 10; getWalletHistory()" v-show="total > historyList.length">더보기<i class="icon-arrow-down"></i></button>
                  <button v-show="!historyList.length">입출금 내역이 없습니다.</button>
                </div>
                <!-- //더보기 -->
              </div>
            </div>
            <!-- //입출금현황 -->
          </div>
        </div>
      </div>
      <!-- 하단버튼 영역 -->
      <div class="fixed_container">
        <div class="sw_button_area">
          <div class="sw_button">
            <div class="btn_table">
              <div class="btn_col" @click="openDepositPopup">
                <button class="addStatus"><span class="btn_dp">입금하기</span></button>
              </div>
              <div class="btn_col" @click="openWithdrawPopup">
                <button class="addStatus"><span class="btn_wd">출금하기</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //하단버튼 영역 -->
      <!-- //메인페이지 -->
    </div>
  </div>
</template>

<script>
import PopupDeposit from "@/components/popup/PopupDeposit";
import PopupLockupDetail from "@/components/popup/PopupLockupDetail";
import PopupWithdraw from "@/components/popup/PopupWithdraw";

export default {
  name: "VueDashboard",

  data() {
    return {
      lockupBalance: '',
      availableBalance: '',
      page: 1,
      limit: 10,
      historyList: [],
      isLockupDetailOpen: false,
      isDepositOpen: false,
      isWithdrawOpen: false,
      rerenderKey: 0,
      total: 0,
    }
  },

  props: ['setDashboardHeader'],

  components: {
    'PopupDeposit': PopupDeposit,
    'PopupLockupDetail': PopupLockupDetail,
    'PopupWithdraw': PopupWithdraw,
  },

  created() {
    this.getWalletHistory()

    this.$get(this.$MAIN_DECIMAL_VIEW, 'mainDecimalView', true, (result) => {
      sessionStorage.setItem('decimalView', result.data[0].length)
    }, (result) => {
      console.log(result)
    })

    this.$get(this.$MAIN_LOCKUP_AMOUNT, 'mainLockupAmount', true, (result) => {
      this.lockupBalance = result.data[0].lockupBalance
    }, (result) => {
      console.log(result)
    })

    this.$get(this.$MAIN_MYBALANCE_AMOUNT, 'mainMyBalanceAmount', true, (result) => {
      this.availableBalance = result.data[0].availableBalance
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.setDashboardHeader()
  },

  methods: {
    closePopup() {
      this.isDepositOpen = false
      this.isWithdrawOpen = false
      this.isLockupDetailOpen = false
    },

    openDepositPopup() {
      this.isDepositOpen = true
      this.$refs.PopupDeposit.showPopup()
    },

    openWithdrawPopup() {
      this.isWithdrawOpen = true
      this.$refs.PopupWithdraw.showPopup()
    },

    openLockupDetail() {
      this.isLockupDetailOpen = true
      this.$refs.PopupLockupDetail.showPopup()
    },

    getWalletHistory() {
      this.$get(`${this.$MAIN_WALLET_HISTORY}?page=${this.page}&limit=${this.limit}`, 'dashboard', true, (result) => {
        this.historyList = result.data
        this.total = result.total
        this.historyList.forEach((history) => {
          history.isTxOpen = false

          let transferType = history.type.split('_')[1]

          if(transferType === 'IN') {
            history.isDeposit = true

            if(history.processState === 'PENDING') {
              history.typeTrans = '입금 대기중'
            } else if(history.processState === 'DONE') {
              history.typeTrans = '입금 완료'
            } else if(history.processState === 'ERROR') {
              history.typeTrans = '입금 에러'
            } else if(history.processState === 'PROCESSING') {
              history.typeTrans = '입금 진행중'
            } else if(history.processState === 'DECLINED') {
              history.typeTrans = '입금 거절'
            } else {
              history.typeTrans = ''
            }
          } else {
            history.isDeposit = false

            if(history.processState === 'PENDING') {
              history.typeTrans = '출금 대기중'
            } else if(history.processState === 'DONE') {
              history.typeTrans = '출금 완료'
            } else if(history.processState === 'ERROR') {
              history.typeTrans = '출금 에러'
            } else if(history.processState === 'PROCESSING') {
              history.typeTrans = '출금 진행중'
            } else if(history.processState === 'DECLINED') {
              history.typeTrans = '출금 거절'
            } else {
              history.typeTrans = ''
            }
          }

          history.formatDate = this.formatDate(history.date)

          if(history.txid === 'safe-withdraw-pending') {
            history.txid = ''
          } else if(history.txid === 'internal') {
            history.txid = ''
          }
        })
      }, (result) => {
        console.log(result)
      })
    },

    copyAddress(addressTo) {
      let tempInput = document.createElement('input')
      tempInput.value = addressTo
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.$notify({ type: 'success', text: '복사되었습니다.' })
    },

    goLockupDetail() {

    }
  },


}
</script>

<style scoped>

</style>