<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="toast_popup">
        <div class="toast_wrap">
          <div class="toast_container">
            <div class="toast_row">
              <!-- 내용 -->
              <div class="toast_content">
                <ul class="list_desc" v-for="(info, infoIdx) in lockupInfo" :key="info.lockupIdx" v-show="lockupInfo.length">
                  <li>
                    <p class="align_between">
                      <span class="dsec_dt">락업수량</span>
                      <span class="dsec_dd">{{ info.lockupBalance }} {{ getCoinLabel() }}</span>
                    </p>
                  </li>
                  <li>
                    <p class="align_between">
                      <span class="dsec_dt">지급된 수량</span>
                      <span class="dsec_dd">{{ Number(info.lockupBalance) - Number(info.remain) }} {{ getCoinLabel() }}</span>
                    </p>
                  </li>
                  <li>
                    <p class="align_between">
                      <span class="dsec_dt">락업시작일</span>
                      <span class="dsec_dd">{{ formatDate(info.createdAt, 'date') }}</span>
                    </p>
                  </li>
                  <li>
                    <p class="align_between">
                      <span class="dsec_dt">락업해지</span>
                      <span class="dsec_dd">{{ info.expiryPerc }}%</span>
                    </p>
                  </li>
                  <hr class="hr_lg_10" v-show="lockupInfo.length - 1 !== infoIdx"/>
                </ul>
                <div class="card-more t-center" v-show="!lockupInfo.length">
                  <button>락업 내역이 없습니다.</button>
                </div>
              </div>
              <!-- //내용 -->
              <!-- 닫기 -->
              <div class="modal_close" @click="closePopup">
                <button class="t-center"><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
export default {
  name: "PopupLockupDetail",

  data() {
    return {
      lockupInfo: [],
      show: false
    }
  },

  methods: {
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true

      if(this.show) {
        this.$get(`${this.$MAIN_LOCKUP_DETAIL}?page=1&limit=100`, 'getPopupLockupDetail', true, (result) => {
          this.lockupInfo = result.data

          this.lockupInfo.forEach(info => {
            if(info.shareAmount === '0' || info.lockupBalance === '0') {
              info.expiryPerc = 0
            } else {
              info.expiryPerc = Number(info.shareAmount) / Number(info.lockupBalance) * 100
            }
          })
        }, (result) => {
          console.log(result)
        })
      }
    },
  }


}
</script>

<style scoped>
.toast_content {
  font-size: 1rem;
  margin-top: 35px;
  max-height: calc(70vh);
  overflow-y: scroll;
  padding: 0 20px 15px;
}

</style>